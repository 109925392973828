// third-party
import { FormattedMessage } from 'react-intl';

// assets

import { IconBrandChrome, IconHelp, IconSitemap, IconCheckbox, IconSchool, IconEyeCheck, IconListDetails } from '@tabler/icons';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconCheckbox,
    CalendarMonthOutlinedIcon,
    IconSchool,
    IconEyeCheck,
    IconListDetails
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'calendar',
            title: <FormattedMessage id="calendar" />,
            type: 'item',
            url: '/calendar',
            icon: icons.CalendarMonthOutlinedIcon,
            children: [
                {
                    id: 'session',
                    title: <FormattedMessage id="Lesson Library" />,
                    type: 'item',
                    url: '/session/:sessionId',
                    icon: icons.IconSchool
                },
                {
                    id: 'coachingAgenda',
                    type: 'item',
                    url: '/coachingAgenda'
                }
            ]
        },
        {
            id: 'attendance',
            title: <FormattedMessage id="attendance-page" />,
            type: 'item',
            url: '/attendance',
            icon: icons.IconListDetails
        },
        {
            id: 'library',
            title: <FormattedMessage id="Lesson Library" />,
            type: 'item',
            url: '/lesson-library',
            icon: icons.IconSchool
        },
        localStorage.getItem('isSupervisor') === 'true'
            ? {
                  id: 'supervisor-review',
                  title: <FormattedMessage id="Supervisor Review" />,
                  type: 'item',
                  url: '/supervisor-review',
                  icon: icons.IconEyeCheck
              }
            : null
    ].filter(Boolean)
};

export default other;
